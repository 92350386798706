import React from "react"
import Container from "../components/container/container";
import SEO from "../components/seo";
import YM from "../components/analytics/ym";
import Remont from "../components/articles/plan-remonta";

const RemontPage = () => (
    <Container>
        <SEO
            title="complexstroy.by | Как спланировать ремонт"
            description="С чего начать ремонт в новостройке: пошаговая инструкция"/>
        <YM />
        <Remont />
    </Container>
);

export default RemontPage
