import React from "react"
import styles from "./article-pages.module.css"
import {Link} from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import LinkButton from "../buttons/link-button/link-button";
import Background from "../../../static/articles/remont/bg.jpg";

const Remont = () => {

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <div className={modal ? styles.articleModal : styles.articlePage}>
                    {modal ? (
                        <Link className={"close-modal"} to={"/#articles"}>&times;</Link>
                    ) : "" }
                    <div className={styles.articleBackground} style={{
                        backgroundImage: `url(${Background})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}>
                        <div className={styles.imageBackground}>
                            <p className={styles.backgroundText}>
                                Как спланировать ремонт
                            </p>
                        </div>
                    </div>
                    <div className={styles.content}>

                        <h3>С чего начать ремонт в новостройке: пошаговая инструкция</h3>

                        <p>Рассказываем о сроках,
                            в которые рекомендуют делать ремонт, а также последовательности работ для
                            квартир с черновой и предчистовой отделкой.</p>

                        <p>Как спланировать и с чего начать ремонт в
                            новостройке? Последовательность действий зависит то того, есть ли
                            облицовка, и насколько качественно она выполнена. Отделка может быть
                            черновой или предчистовой, при которой используются бюджетные материалы.
                            Чаще всего ее нет вообще. Около 6% квартир сдается с в готовом виде. В
                            современных домах часто отсутствуют перегородки. Предполагается, что хозяин
                            должен возвести их своими силами, либо оставить все как есть — у просторных
                            помещений есть свои плюсы. Поэтапность также зависит от времени года и от
                            того, включено ли отопление. Не исключено, что в первую очередь придется
                            заняться условиями для проживания бригады.
                        </p>

                        <h3>Когда можно приступать к отделке</h3>

                        <p>Один из важнейших нюансов — материал, из
                        которого построен дом. Стены, пол и потолок в первые два года дают усадку. Она
                        приводит к появлению трещин на оштукатуренной поверхности. В кирпичных строениях
                        этот период занимает от 5 до 10 лет. Со стяжкой и напольным покрытием спешить не
                        стоит. Горизонтальные плиты должны окончательно встать на свое место.
                        Межпанельные стыки в железобетонных конструкциях испытывают наибольшие
                        деформации. В монолитных домах они наименее
                        заметны.</p>

                        <div>
                            <blockquote>Чтобы понять, дало ли здание
                                окончательную усадку, придется провести экспертизу. Для этого следует
                                обратиться в инжиниринговую кампанию.<br/></blockquote>
                        </div>

                        <p>Если нужно срочно вселиться на новую жилплощадь, и времени на ожидание нет, есть два решения проблемы.</p>

                        <p>
                            Можно использовать штукатурку и шпаклевку с пластифицирующими добавками, повышающими
                        их упругость. Они способны сжиматься и растягиваться вместе с основанием. Их
                        внешняя сторона испытывает меньше нагрузок от перемещения. Отделочный слой гасит
                        деформации, поэтому краска, обои или другое чистовое покрытие не пострадают. Этот способ прекрасно
                        подходит для кухни, ванной и туалета — плитка укладывается на эластичный клей с
                        пластификаторами.
                        </p>

                        <p>Второй вариант — предчистовая облицовка. Для нее используются материалы,
                            которые будет не жалко поменять через два года на чистовое финишное
                            покрытие. Основание лучше оклеить обоями без шпаклевки и штукатурки. Не
                            следует уделять много внимание дизайну интерьера. Покрытие должно быть
                            удобным и экологичным, но не более того. Нужно быть готовым к тому, что на
                            стенах и потолке вскоре появятся трещины и отслоения. Это является серьезным
                            недостатком данного метода. Преимущество его заключается в возможности
                            использования после усадки любых
                            материалов.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/remont/1.jpg"/>
                        </div>

                        <h3>Планирование</h3>

                        <p>Действовать следует поэтапно.</p>

                        <h3>Закупка материалов</h3>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/remont/2.jpg"/>
                        </div>

                        <p>Необходимо заранее продумать, где будут храниться
                        стройматериалы и сантехника, хватит ли средств на их закупку и оплату услуг
                        строительной фирмы, долго ли идет заказ. Делая заказ из Европы, нужно рассчитать
                        время, которое он проведет в пути. В августе все европейские фирмы закрываются.
                        Их сотрудники уходят в отпуск, поэтому время ожидания в конце лета значительно
                        увеличится.</p>

                        <div>
                            <blockquote>Запас должен составлять не менее
                                10% на случай брака и порчи при укладке. Используют материалы из одной
                                партии — только так можно добиться однородного цвета и рельефа.
                                Одинаковые плитки или рулоны обоев из разных партий заметно отличается
                                друг от друга.<br/></blockquote>
                        </div>

                        <h3>План перепланировки</h3>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/remont/3.jpg"/>
                        </div>

                        <p>Если предстоит перепланировка, ее также вносят в график. Разработка и согласование проекта могут занять
                        несколько месяцев. В каждой области действует региональное
                        законодательство, уточняющее список запрещенных мероприятий. В Москве таким
                        документом является Постановление № 508 ПП «Об организации переустройства и
                        (или) перепланировки жилых и нежилых помещений в многоквартирных домах». Их
                        изучение — это именно то, с чего следует начать ремонт в новостройке. Когда
                        работы начнутся, желательно воздержаться от спонтанных решений — они могут
                        оказаться как минимум незаконными. Необходимо пошагово спланировать все свои
                        действия и сделать это заранее.
                        </p>

                        <h3>Чего нельзя делать по закону</h3>

                        <ul>
                            <li>Установка оборудования, оказывающего влияние на потребление ресурсов в
                                соседних помещениях.
                            </li>
                            <li>Полный или частичный снос вентиляционных каналов.</li>
                            <li>Перенос отопительных приборов, подключенных к стояку, на балкон или
                                лоджию.
                            </li>
                            <li>Подключение системы теплого пола к трубам ГВО или центрального
                                отопления.
                            </li>
                            <li>Прокладка каналов в панелях и межпанельных швах. Допускается штрабление
                                в слое штукатурки. Стены из кирпича под запрет не попадают, но они могут
                                быть сильно изношены. Перед штраблением необходимо провести их
                                обследование. Для этого понадобится специальное оборудование, которым
                                располагают инжиниринговые организации.
                            </li>
                            <li>Расширение санузла за счет жилых помещений.</li>
                            <li>Устройство проемов без согласования проекта. Их нельзя делать в
                                колоннах, в межпанельных швах и других местах, где стыкуются сборные
                                элементы.
                            </li>
                            <li>Ослабление несущих конструкций, создание условий, при которых они
                                испытывают нагрузки сверх допустимых. В зданиях типовых серий перегородки
                                лучше делать из гипсокартона – кирпич может оказаться слишком
                                тяжелым. Если планируется увеличить нагрузку, желательно сначала
                                провести обследование несущих конструкций. В старых постройках они
                                значительно ослаблены. Причиной тому — возраст, износ и незаконные
                                перепланировки.
                            </li>
                            <li>Самовольное устройство лоджий, балконов, создание фундамента под ними,
                                даже если они предусмотрены проектом. Также запрещено их
                                объединение с внутренними помещениями.
                            </li>
                            <li>Перепланировка в подъезде, на чердаке и
                                в подвале, если они являются общедомовой собственностью. Подобные работы
                                допускаются только с согласия всех жильцов;
                            </li>
                            <li>Объединение комнаты с кухней, если в ней установлена газовая плита.
                                Между ними обязательно должна быть плотно закрывающаяся дверь.
                            </li>
                            <li>Изменения внешнего вида фасада, если здание является памятником
                                архитектуры. В домах типовых серий любые изменения согласовываются в
                                государственных инстанциях.
                            </li>
                            <li>Устройство более одной антресоли в помещении.</li>
                            <li>Установка на антресоли кухонных плит и сантехники.</li>
                        </ul>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/remont/4.jpg"/>
                        </div>

                       <h3>Последовательность работ при ремонте квартиры в новостройке</h3>

                        <p>Перед тем как начать
                            непосредственно ремонт, советуем провести замену замков на входных
                            дверях, чтобы обезопасить себя от непрошенных гостей. Не исключено, что
                            дубликаты ключей остались у строителей, представителей управляющей организации
                            или покупателей, отказавшихся приобретать
                            жилье.
                        </p>

                        <p>Важно учесть некоторые технические аспекты. Перенос стояка проще проводить
                            летом, когда он отключен. Получить согласие на его отключение у управляющей
                            организации в зимний период удается не всегда. Кирпичную кладку и
                            цементную стяжку на лоджии или в неотапливаемой квартире лучше делать в
                            теплое время. При отрицательной температуре цементный раствор схватывается
                            дольше и не набирает марочную прочность. Устанавливать стеклопакеты не
                            рекомендуется при сильных морозах. Чтобы избежать риска, желательно отложить
                            их установку на лето.
                        </p>

                        <p>Планируя ремонт в новостройке с нуля поэтапно, необходимо следовать важному принципу: при
                        облицовке двигаться нужно сверху вниз — иначе можно испачкать готовое покрытие
                        или привести его в
                        негодность.</p>

                        <div>
                            <blockquote>Специалисты советуют приступать к
                                чистовой отделке только после завершения черновой. Иначе можно
                                поцарапать плитку или паркет частицами цемента или песка. Стены несложно
                                будет испортить при перемещении ванны либо других крупногабаритных
                                предметов.<br/></blockquote>
                        </div>

                        <h3>Черновые работы</h3>

                        <ul>
                            <li>
                                Проводка электрики — чтобы подключить свет и розетки нужно установить
                                распределительный щиток. Без него будет невозможно использовать
                                болгарки, перфораторы, другой инструмент, подключающийся к сети. Должен
                                быть продуманный план разводки, где отмечено положение розеток,
                                осветительных приборов, стиральной машины, прочего оборудования.
                            </li>
                            <li>Замена приборов отопления — если выполнить ее после отделки, радиатор
                                может не поместиться в свою нишу по причине утолщения облицовочного
                                слоя.
                            </li>
                            <li>Устройство перегородок в квартирах со свободной планировкой.</li>
                            <li>Монтаж коммуникаций для сантехники, создание оснований из кирпича или
                                бетона.
                            </li>
                            <li>
                                Стяжка пола.
                            </li>
                            <li>Оштукатуривание и шпаклевка.</li>
                            <li>Облицовка кухни и санузла.</li>
                            <li>Установка подвесных потолков либо покраска перекрытия.</li>
                            <li>Замена оконных коробов, подоконников и стеклопакетов.</li>
                            <li>
                                Монтаж сантехники — его лучше проводить перед финишным покрытием чтобы
                                не повредить поверхность. Сантехнику необходимо закрыть листами картона
                                пленкой.
                            </li>
                        </ul>

                        <h3>Чистовые работы</h3>

                        <ul>
                            <li>Финишная обработка стен и пола.</li>
                            <li>Установка межкомнатных дверей.</li>
                            <li>Сборка встраиваемой мебели, например, стенных шкафов.</li>
                            <li>Монтаж электроприборов, розеток, выключателей, плинтусов, закрывающих
                                провода.
                            </li>
                        </ul>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/remont/5.jpg"/>
                        </div>

                        <p>Шаги ремонта в новостройке могут отличаться в каждом отдельном случае. Мы
                            дали лишь общую схему, которая не может служить подробной пошаговой
                            инструкцией.</p>

                        <h3>Инструкция для квартиры с черновой отделкой</h3>

                        <p>Как мы уже упоминал выше, дом
                        должен дать усадку, занимающую несколько лет. В этот период стены, пол и потолок
                        претерпевают сильную деформацию. Обычная стяжка и штукатурка в таких условиях
                        могут дать трещину, поэтому используются материалы, которые не жалко будет
                        заменить, когда усадка закончится. Как правило, они не отличаются высоким
                        качеством и рассчитаны на замену. Это не означает, что застройщиком могут быть
                        допущены нарушения санитарно-технических
                        нормативов.</p>

                        <p>Как правило, в помещениях уже сделана стяжка, вертикальные поверхности заштукатурены
                        и зашпаклеваны. Проводка готова, но розеток еще нет. Трубы подсоединены к
                        стояку — осталось подключить их к сантехнике. Иногда застройщик устанавливает
                        сантехнику сам, но это в основном бюджетные модели. То же можно сказать и о
                        дверях, стеклопакетах, отопительных приборах. Их качество оставляет желать
                        лучшего. Не стоит спешить с капитальным ремонтом. На первых порах можно
                        ограничиться следующими
                        действиями.</p>

                        <h3>Потолки</h3>

                        <p>Чтобы компенсировать деформации при усадке, используют подвесные
                            потолочные системы. Если позволяет высота, устанавливают плоский
                            металлический каркас и обшивают его листами гипсокартона. Каркас собирают из
                            алюминиевого профиля и крепят на перекрытие с помощью дюбелей и шурупов. Для
                            низких помещений подойдут натяжные потолки — они отнимают меньше пространства и представляют собой багет,
                            который крепится по периметру. На багет натягивается полотно, изготовленное из
                            поливинилхлорида. Такая система прекрасно выдерживает любое смещение
                            конструкций. Менять его не придется. Кроме того, оно не протекает.
                        </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/remont/6.jpg"/>
                        </div>

                        <h3>Стены</h3>

                        <p>Стены в санузле можно покрасить либо покрыть кафельной плиткой, предварительно обклеив
                        их листами гипсокартона. Есть и другое решение. В цементный клей добавляют
                        пластифицирующие добавки, делающие состав более эластичным. Такая облицовка
                        простоит долго и усадка ей не страшна. Установка сантехники
                        производится по окончании черновых
                        работ.</p>

                        <p>Можно смело наклеивать обои. Смещения внутри панелей не так велики, чтобы порвать полотно.
                            Наибольшей эластичностью обладают виниловые обои. Они прекрасно тянутся и
                            легко принимают форму изгибов. При использовании штукатурки желательно
                            отдать предпочтение составам с пластификаторами. Декоративные смеси
                            наносятся тонким слоем с помощью шпателя. Многие из них содержат полимеры,
                            делающие их упругими. В сочетании с мягким черновым слоем они создают
                            надежное покрытие, выдерживающее небольшие смещения основания.
                        </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/remont/7.jpg"/>
                        </div>

                        <h3>Пол</h3>

                        <p>На пол лучше постелить линолеум. При необходимости его не сложно будет снять и сделать чистовую стяжку.</p>

                        <p>Когда финишное покрытие готово, переходим к монтажу розеток, выключателей и
                            осветительных приборов. В завершении всего процесса устанавливаем
                            встроенную мебель и технику. Проводку прячем под
                            плинтуса.
                        </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/remont/8.jpg"/>
                        </div>

                        <p>Если рассмотреть
                            этапы ремонта в новостройке с нуля пошагово и сравнить их с перечнем работ при
                            готовой черновой отделке, можно убедиться, что затраты будут почти одинаковыми.
                            Главный совет — не спешить с окончательной облицовкой. Выгоднее потратить время
                            ожидания на разработку и согласование продуманного
                            проекта.
                        </p>
                        <div style={{textAlign: 'center', marginTop: '40px'}}>
                            <LinkButton text={'Вернуться на сайт'} path={modal ? "/#articles" : ""}/>
                        </div>
                    </div>
                </div>
            )}
        </ModalRoutingContext.Consumer>
    );
};

export default Remont
